import React from 'react';

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const handleMessage = async (userContent, suggestion) => {

    console.log(userContent,suggestion,"hey handle")
    // Create user message
    const userMessage = createChatBotMessage(userContent);
    
    // Create bot message
    const botMessage = createChatBotMessage(suggestion);

    // Update chatbot state with both user and bot messages
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, userContent, suggestion],
    }));
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleMessage, // You can call this function to send messages to the chatbot
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
