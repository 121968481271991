import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { alpha, withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import List from "@material-ui/core/List";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { BgColor, Fonts, Color } from "../../theme";
import Box from "@material-ui/core/Box";
import classNames from "classnames";
import { logout } from "../../redux/actions/authActions";
import Badge from "@material-ui/core/Badge";
import { useTranslation } from 'react-i18next';
import zIndex from "@material-ui/core/styles/zIndex";
import { valHooks } from "jquery";
import { Typography } from "@material-ui/core";
import { logo, logo_chi } from "../../assets";
import Avatar from "@material-ui/core/Avatar";
import CloseIcon from "@material-ui/icons/Close"; // Import CloseIcon

const drawerWidth = "18vw";

const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    color: "#2b65ec",
    fontWeight: "800",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    flexShrink: 0,
    zIndex:999,
  },
  drawerPaper: {
    width: drawerWidth,
    minWidth: "220px",
    maxWidth: "240px",
    margin: "20px",
    marginTop: "60px",
    height: "90vh",
    paddingTop: "10px",
    borderRadius: "20px",
    backgroundColor: "#fff !important",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Add box shadow here
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  activeBtn: {
    backgroundColor: "rgba(82, 129, 239,0.2)",
    borderLeft: "4px solid" + BgColor.myBlue,
    borderTop: "1px solid rgba(0, 0, 0, 0.05)",
    paddingTop: "10px",
    paddingBottom: "10px",
    transition: "all .05s ease",
    "& > .MuiListItemIcon-root": {
      color: BgColor.myBlue,
    },
    "& .MuiSvgIcon-root": {
      transition: "all .05s ease",
    },
    "& > .MuiTypography-body1": { fontFamily: Fonts.Regular },
  },
  navigationText: {
    fontSize: "15px",
    fontFamily: Fonts.Regular,
  },
  disableTab : {
    backgroundColor : 'darkgoldenrod !important'
  },
  navButtons: {
    borderTop: "1px solid rgba(0, 0, 0, 0.05)",
    padding: "10px",
    borderRadius: "10px !important",
    marginLeft:"5px",
    color:"#2b65ec",
    width:"auto !important",
    marginRight:"5px",
    transition: "background-color 0.05s, color 0.05s",
    "&:hover": {
      backgroundColor: "#2b65ec !important",
      borderRadius: "10px !important",
      color: "#fff !important",
    },
    "&:hover .MuiSvgIcon-root": {
      fill: "#fff !important",
      transition: "background-color 0.05s, fill 0.05s",
    },
    "& .MuiSvgIcon-root": {
      fill: "#2b65ec !important",
    }
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
    fill: "#2b65ec !important",
    zIndex: 9999,
  },
});

const DrawerComponent = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { classes, state, setState, notificationCount } = props;
  const [isSubOpen, setIsSubOpen] = useState(false);
  const auth = useSelector((store) => store.auth);
  const location = useLocation(); // Get the current location
  
  const handleClick = () => {
    setIsSubOpen((isSubOpen) => !isSubOpen);
  };

  useEffect(() => {
    if (state.menu) {
      let _menu = state.menu.filter(e => e.show != false)
      setState((state) => ({
        ...state,
        menu: _menu,
      }));
    }
  }, []);

  const activeNavigation = (currentPathname) => {
    setState((prevState) => ({
      ...prevState,
      menu: prevState.menu.map((item) => ({
        ...item,
        active: item.link === currentPathname,
      })),
    }));
  };

  const handleCloseDrawer = () => {
    setState((prevState) => {
      const newState = {
        ...prevState,
        drawer: false, // Set drawer state to false to close it
      };
  
      const dashboard = document.querySelector('.MuiContainer-maxWidthXl');
      if (dashboard) {
        const isAboveMd = window.matchMedia('(min-width: 960px)').matches; // Assuming 'md' breakpoint is 960px
        if (isAboveMd) {
          // Access drawer state from newState object
          if (newState.drawer) {
            dashboard.style.marginLeft = '240px';
          } else {
            dashboard.style.marginLeft = '0px';
          }
        } else {
          // Ensure no margin-left is applied for smaller screens
          dashboard.style.marginLeft = '0px';
        }
      }
  
      return newState;
    });
  };
  

  return (
    <SwipeableDrawer
      className={classes.drawer}
      variant="persistent"
      classes={{
        paper: classes.drawerPaper,
      }}
      open={state.drawer}
      onOpen={(event) => {}}
      onClose={(event) => {}}
    >
      {/* Close button */}
     
      <div className={classes.drawerContainer}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Avatar sizes={"20"} src={t("login") == "LOGIN" ? logo : logo_chi} />
          <Typography style={{ marginLeft: "10px", color: "#2b65ec" }} variant="h6" noWrap>
            Irevu
          </Typography>
          <CloseIcon className={classes.closeButton} onClick={handleCloseDrawer} />

        </div>
        <hr style={{ width: "80%", marginLeft: "10%", height: "1px", background: "#ffffff !important" }} />
        <List key={"topList"}>
          {state.menu.map((item, index) => {
            return (
              <div key={index}>
                <ListItem
                  key={index}
                  onClick={() => {
                    if (item.key === "logout") {
                      dispatch(logout());
                      if (!auth.isLoggedIn) {
                        window.location.reload();
                      }
                    } else if (item.expandable) {
                      activeNavigation(location.pathname);
                      handleClick();
                    } else {
                      activeNavigation(location.pathname);
                      // Set active navigation based on link
                      if (!item.disable) {
                        props.history.push(item.link);
                      }
                    }
                  }}
                  button
                  className={classNames(
                    item.active ? classes.activeBtn : classes.navButtons,
                    item.disable ? classes.disableTab : null
                  )}
                  style={{
                    borderBottom: index === state.menu.length - 1 ? "1px solid rgba(0, 0, 0, 0.05)" : "",
                    borderTop: index === 0 ? "0px solid rgba(0, 0, 0, 0.05)" : "",
                    marginTop: index === 0 ? "-5px" : "",
                  }}
                >
                  <ListItemIcon style={{ marginRight: "-15px", fill: "#2b65ec !important" }}>
                    {item.icon}
                  </ListItemIcon>
                  <Box className={classes.navigationText}>
                    {t(item.title)} {item.key === "notifications" ? <Badge badgeContent={notificationCount} color="secondary"></Badge> : ('')}
                  </Box>
                  {item.expandable ? (isSubOpen ? (<ExpandLess />) : (<ExpandMore />)) : ("")}
                </ListItem>
                {item.expandable && (
                  <Collapse in={isSubOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {item.expandable.map((expand, Tindex) => {
                        return (
                          <ListItem className={classes.nested} button key={expand.title + Tindex}>
                            <ListItemIcon style={{ marginRight: "-15px" }}>
                              {expand.icon}
                            </ListItemIcon>
                            <Box className={classes.navigationText}>
                              {expand.title}
                            </Box>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                )}
              </div>
            );
          })}
        </List>
      </div>
    </SwipeableDrawer>
  );
};

export default withStyles(styles, { withTheme: true })(DrawerComponent);
