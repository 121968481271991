import React from 'react'
import Header from '../../../components/header'
import { useHistory, useParams } from 'react-router-dom';

const ScorePage = (props) => {
  const history = useHistory();
  const { lessonId } = useParams();
  const { score } = props.history.location.state || {};

  return (
    <div>
      <Header history={history} showButtons={false}/>
      <div>
        <h2>Score</h2>
        <div>
          <span>score: </span>
          <span>{score.score}</span>
        </div>
        <div>
          <span>Total Score: </span>
          <span>{score.totalScore}</span>
        </div>
      </div>
    </div>
  )
}

export default ScorePage