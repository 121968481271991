import React from 'react';
import { useHistory } from 'react-router-dom';

const ActivityListPopUp = ({setLessonPlanActivity, activities, lessonId }) => {
  const history = useHistory();

  const handleClick = (activity) => {
 
    history.push(`/course-creator/activity/${lessonId}/${activity?.activity_name.replace('/', '-')}`);
  };

  return (
    <div className="popup">
      <h2 style={{color: "#fff"}}> Activities</h2>
      <ul>
        {activities.map((activity, index) => (
          <li
            key={index}
            onClick={() => handleClick(activity)}
            style={{
              backgroundColor: activity?.status === 'complete' ? 'green' : 'orange',
              color: '#fff',
              cursor: 'pointer',
              padding: '10px',
              margin: '5px 0',
            }}
          >
            {activity.activity_name} {activity.status === 'complete' ? ' - Complete' : ''}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ActivityListPopUp;
