import axios from 'axios';
import React from 'react'
import { useParams, useHistory } from 'react-router-dom';
import Const from '../../../helpers/const';

const PopUpNextActivity = ({
  heading, 
  subHeading, 
  leftButton, 
  rightButton, 
  handleLeftButton, 
  handleRightButton, 
  loading = false,
}) => {
  const { lessonId, activityType } = useParams();
  const history = useHistory()

  const handelSubmitAndExit = async() => {
    history.goBack()
  }

  const handelSubmitAndContinue = async () => {
    const response = await axios.get(`${Const.BASE_URL}get/${lessonId}/lessonplan`)
    const activities = response?.data?.lesson_plans[0]?.activities
    const index  = activities.findIndex(activity => activity.activity_name.replace('/', '-') === activityType);
    console.log("activity: ", index);
    if(index === -1 || index === activities.length-1){
      console.log("return");
      return null;
    }
    
    history.push(`/course-creator/activity/${lessonId}/${activities[index+1]?.activity_name.replace('/', '-')}`);
  }

  
  return (
    <div className='pop-up-warning-container'>
      {
        loading 
        ? <div className="spinner"></div>
        :<>
          <div>{heading}</div>
          <div>{subHeading}</div>

          <div>
            <button 
              className='custom_btn'
              color="primary"
              variant="contained"
              style={{ marginLeft: 5 }} 
              onClick={() => {handelSubmitAndExit()}}
            >
              {leftButton}
            </button>

            <button 
              className='custom_btn'
              color="primary"
              variant="contained"
              style={{ marginLeft: 5 }}
              onClick={async() => {
                await handelSubmitAndContinue()
              }}
            >
              {rightButton}
            </button>
          </div>
        </>
      }

    </div>
  )
}

export default PopUpNextActivity