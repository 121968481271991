import React from 'react';
import { createChatBotMessage } from 'react-chatbot-kit';
import { createCustomMessage } from 'react-chatbot-kit';
import { createClientMessage } from 'react-chatbot-kit';
import { CustomChatMessage,MyUserChatMessage } from './My';

const config = {
  initialMessages: [
    // createCustomMessage("ok"),
    createClientMessage("h;ooooooooook"),
    createChatBotMessage(`👋 Hello`),
    // createCustomMessage("ok"),
  ],
  
  customStyles: {
    botMessageBox: {
      backgroundColor: '#4A90E2', // Modern blue shade
      borderRadius: '10px',
      padding: '10px',
      color: '#ffffff', // White text for contrast
      fontWeight: 'bold',
      fontSize: '16px',
    },
    chatButton: {
      backgroundColor: '#50E3C2', // Vibrant green for action button
      borderRadius: '50%', // Circular button
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
      width: '60px',
      height: '60px',
    },
    userMessageBox: {
      backgroundColor: '#F5A623', // Warm orange for user messages
      borderRadius: '10px',
      padding: '10px',
      color: '#ffffff',
      fontWeight: 'bold',
    },
  },
  customComponents: {
    header: () => (
      <div
        style={{
          backgroundColor: '#2A2A2A', // Dark background for header
          color: '#ffffff', // White text
          padding: '15px',
          fontSize: '20px',
          fontWeight: 'bold',
          borderRadius: '10px 10px 0 0',
          textAlign: 'center',
        }}
      >
        💬 Suggestion ChatBot
      </div>
    ),

  },
  
};

export default config;
