import React from 'react'

const PopUpWarning = ({
  heading, 
  subHeading, 
  leftButton, 
  rightButton, 
  handleLeftButton, 
  handleRightButton, 
  loading = false,
}) => {
  return (
    <div className='pop-up-warning-container'>
      {
        loading 
        ? <div className="spinner"></div>
        :<>
          <div>{heading}</div>
          <div>{subHeading}</div>

          <div>
            <button 
              className='custom_btn'
              color="primary"
              variant="contained"
              style={{ marginLeft: 5 }} 
              onClick={handleLeftButton}
            >
              {leftButton}
            </button>

            <button 
              className='custom_btn'
              color="primary"
              variant="contained"
              style={{ marginLeft: 5 }}
              onClick={handleRightButton}
            >
              {rightButton}
            </button>
          </div>
        </>
      }

    </div>
  )
}

export default PopUpWarning