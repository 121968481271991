import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import BotAvatar from '../../../../assets/images/Frame 436.png'
// from '../../../assets/images/Frame 436.png'
import UserAvatar from '../../../../assets/images/Frame 437.jpg'

import MarkdownRender from '.././MarkdownRender';
import { Button, Grid } from '@mui/material';
// import PopUpWarning from './PopUpWarning';
// import PopUpNextActivity from './PopUpNextActivity';
  
const WritingChatbot = ({suggest,contentUser,selectWritingActivity}) => {
  const { t } = useTranslation();
  const history = useHistory()
 
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  
  const [messages, setMessages] = useState([]);
  console.log("message>>",messages);
  
  console.log("message>>>",messages);
  console.log("contentUser>>>",contentUser);
  
  const [UserMessage,setUserMessage] = useState([])
  const [inputMessage, setInputMessage] = useState('');
  const [boatReplyPending, setBoatReplyPending] = useState(false);
  const [currentPopShow, setCurrentPopShow] = useState(null);
  const [botResponseCount, setBotResponseCount] = useState(0); // Initialize a counter for bot responses
 const [Bot,setBot]= useState([])
 console.log("Bot>>",Bot);
 

  const handelScrollToLastMessage = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
     }
  }


  const botDebateMessageReply = async (inputMessage, Bot, messages = []) => {
    // Update messages with the latest user input
    messages.push({
        role: "user",
        content: contentUser,
    });

    // Ensure the debate topic is defined

    const response = await axios.post(
        "https://irevu.openai.azure.com/openai/deployments/irevu4o0/chat/completions?api-version=2024-02-15-preview",
        {
            messages: [
                {
                    role: "system",
                    content: `You are tasked with improving the user's writing based on the activity: "${JSON.stringify(
                        selectWritingActivity
                    )}". Analyze the user's content, infer their intended message, and provide feedback that: 
                    1. Corrects grammatical errors and enhances vocabulary.
                    2. Suggests improvements that are relevant and aligned with the topic "${selectWritingActivity}".
                    3. Builds upon previous suggestions to logically progress the essay. If the topic remains the same and previous responses are present in the conversation, provide a unique follow-up suggestion to move the essay forward. 
                    Ensure the suggestions are concise, do not exceed the user's word count by more than 20 words, and remain coherent with the topic.`,
                },
                {
                    role: "user",
                    content: `Here is the user's current content: ${JSON.stringify(contentUser)}`,
                },
                {
                    role: "system",
                    content: `Provide suggestions based on the above guidelines and consider the conversation history: ${JSON.stringify(
                        messages
                    )}.`,
                },
                {
                    role: "system",
                    content: `Provide suggestions based on the above guidelines and consider the conversation history: ${JSON.stringify(
                        messages
                    )}. Do not repeat responses from previous interactions in bot: ${JSON.stringify(messages)}`,
                },
            ],
            max_tokens: 4000,
            temperature: 0.7,
            frequency_penalty: 0,
            presence_penalty: 0,
            stop: null,
        },
        {
            headers: {
                "Content-Type": "application/json",
                "api-key": "7327443ae85e44419192e4fb0544d702",
            },
        }
    );

    // Save the bot's reply to the conversation history
    const botResponse = response.data.choices[0].message.content;
    messages.push({
        role: "assistant",
        content: botResponse,
    });

    return botResponse;
};


  const handleSendMessage = async () => {
    console.log("contentin bot",contentUser);
    
    if (contentUser.trim() !== '') {
        console.log("content in bot inner",contentUser);
      setMessages([...messages, { sender: 'user', text: contentUser }]);
      setUserMessage([{ sender: 'user', text: contentUser }]);
      setBoatReplyPending(true);

      // const botReply = await botDebateMessageReply(inputMessage)
      
      // setMessages(prevMessages => [...prevMessages, { sender: 'bot', text: botReply }]);
      // if (botResponseCount ) { 
        const botReply = await botDebateMessageReply(inputMessage,Bot);
        setBot((prevBot =>[...prevBot,botReply]))
        setMessages(prevMessages => [...prevMessages, { sender: 'bot', text: botReply }]);
        setBotResponseCount(prevCount => prevCount + 1);

      // } else {
       
        // const thankYouMessage = "Conversession Is Limited To Only 10 Chat";
        // setMessages(prevMessages => [...prevMessages, { sender: 'bot', text: thankYouMessage }]);
      // }

      setInputMessage("");
      setBoatReplyPending(false);
      setInputMessage('');
      inputRef.current.focus();
    }
  };


  useEffect(()=>{
      if(contentUser){
        handleSendMessage(contentUser)
      }
  },[contentUser])

  const handleKeyDown = (e) => {
    handelScrollToLastMessage();
    if (e.key === 'Enter' && !boatReplyPending) {
      handleSendMessage();
    }
  };



  useEffect(() => {
    handelScrollToLastMessage();
  }, [messages])
  
  return (
    <div  className='WriteChat_outer'>
      {/* { selectedDebateTopic === null? */}
        {/* (
          <div className='debate_secs'>
            {
              topicList?.map( (topic, index) => (
                <div onClick={() => setSelectedDebateTopic(topic)}>
                    {index}. {topic}
                </div>
              ))
            }
          </div>
        )
        : */}
        
          <div className='page_outer chat_cust WriteChat'>
          <div className='WriteChat_inner_box inner_box'>
           <div className='top_heading'>
            <div className='heading-cont-spl'><h2><span> Bot Suggestions</span></h2></div>
          
           {/* <h4>D : {selectedDebateTopic}</h4> */}
           <div className='chat_lists'>
            <div className="chat-messages">
              {messages.map((message, index) => (
                <div key={index} className={`message ${message.sender==='bot'?'user': 'bot'}`}>
                  <div className="avatar">
                    {message.sender === 'bot' ? <img src={BotAvatar} alt='boat' loading='lazy' /> : <img src={UserAvatar} alt='boat' loading='lazy' />}
                  </div>
                  <MarkdownRender text={message.text} />
                  {
                    (messages.length -1) === index && 
                    <div id='refDiv' ref={messagesEndRef} />

                  }
                </div>
              ))}
              {
                boatReplyPending && <div className="spinner"></div>
              }
            </div>
            <div className="chat-input write_Chat_bot">
              <input
                type="text"
                ref={inputRef}
                disabled={boatReplyPending}
                value={boatReplyPending ? "" : inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                placeholder="Type a new message here"
                onKeyDown={handleKeyDown}
              />
              <button className='btn' onClick={ boatReplyPending===false && handleSendMessage}>
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M20.4653 11.5875L31.7413 16.6607C35.1691 18.2029 37.8667 19.4165 39.6968 20.5659C41.5043 21.7011 42.9168 23.047 42.9168 25C42.9168 26.953 41.5043 28.299 39.6968 29.4342C37.8667 30.5835 35.1691 31.7972 31.7414 33.3393L20.4653 38.4125C18.3703 39.3551 16.7405 40.0884 15.4678 40.5643C14.2225 41.03 13.1095 41.3358 12.1199 41.2282C9.71954 40.9672 7.65405 39.3212 7.15415 36.9973C6.94228 36.0124 7.23854 34.9106 7.66419 33.7661C8.10491 32.581 8.80538 31.0594 9.69776 29.1209L9.72182 29.0686C10.4193 27.5535 10.6877 26.9557 10.8246 26.3593C11.0306 25.4621 11.0306 24.5379 10.8246 23.6407C10.6877 23.0443 10.4193 22.4465 9.72182 20.9314L9.69776 20.8792C8.80538 18.9407 8.10491 17.4191 7.66419 16.234C7.23854 15.0894 6.94228 13.9876 7.15415 13.0027C7.65405 10.6789 9.71954 9.03287 12.1199 8.77185C13.1095 8.66423 14.2225 8.97006 15.4678 9.43574C16.7405 9.91163 18.3703 10.6449 20.4653 11.5875ZM14.5922 11.7774C13.3686 11.3198 12.7435 11.2188 12.3902 11.2572C10.9101 11.4181 9.84116 12.3992 9.59824 13.5285C9.55304 13.7386 9.5846 14.2257 10.0074 15.3626C10.4112 16.4483 11.0712 17.8841 11.9928 19.886C12.0129 19.9299 12.0329 19.9732 12.0526 20.0161C12.671 21.3589 13.0594 22.2024 13.2612 23.0812C13.5518 24.3466 13.5518 25.6534 13.2612 26.9188C13.0594 27.7977 12.671 28.6412 12.0526 29.984C12.0329 30.0268 12.0129 30.0701 11.9928 30.114C11.0712 32.116 10.4112 33.5517 10.0074 34.6375C9.5846 35.7744 9.55304 36.2614 9.59824 36.4716C9.84116 37.6008 10.9101 38.5819 12.3902 38.7428C12.7435 38.7812 13.3686 38.6802 14.5922 38.2227C15.7794 37.7787 17.3351 37.0795 19.4847 36.1123L30.6145 31.1049C34.165 29.5075 36.702 28.3628 38.3671 27.3171C40.0727 26.2459 40.4168 25.5501 40.4168 25C40.4168 24.45 40.0727 23.7541 38.3671 22.683C36.702 21.6372 34.165 20.4925 30.6145 18.8951L19.4847 13.8877C17.3351 12.9206 15.7794 12.2213 14.5922 11.7774Z" fill="#02040F"/>
</svg>
              </button>
            </div>
            </div> </div></div>

            {/* <Grid className='question_btn_list' item xs={12} container justifyContent="center">
           
            </Grid> */}

          </div> 
    
     </div>
  );

};


export default WritingChatbot;
