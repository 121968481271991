import React, { useState, useRef } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "80vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  overflowY: "auto",
  p: 4,
};

const EditFormCardAI = ({
  open,
  handleClose,
  lessonPlanResponse,
  setLessonPlanResponse,
}) => {
  const editorRef = useRef();
  const [cursorPosition, setCursorPosition] = useState(0);

  const handleInputChange = (content) => {
    setLessonPlanResponse(content);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;
    if (files.length > 0) {
      const file = files[0]; // Only handling one file at a time
      const reader = new FileReader();
      reader.onload = (event) => {
        const quill = editorRef.current.getEditor();
        quill.setSelection(cursorPosition);
        quill.insertEmbed(cursorPosition, "image", event.target.result);
        quill.setSelection(cursorPosition + 1); // Move cursor after image
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEditorChange = (content, delta, source, editor) => {
    handleInputChange(content);
    setCursorPosition(editor.getSelection()?.index || 0);
  };

  const editorStyle = {
    flex: 1,
    overflowY: "auto",
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={style}
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Edit Lesson Plan
        </Typography>
        <ReactQuill
          ref={editorRef}
          value={lessonPlanResponse}
          onChange={handleEditorChange}
          style={editorStyle}
        />

        <Button onClick={handleClose} sx={{ mt: 2 }}>
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default EditFormCardAI;
