import React, { useEffect, useState } from 'react'
import { Grid,Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PopUpWarning from './PopUpWarning';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PopUpNextActivity from './PopUpNextActivity';
const HomeWorkActivities = ({HomeActivity}) => {
    const [allTask,setALLTask] = useState([])
    const [currentPopShow, setCurrentPopShow] = useState(null);
    const history = useHistory()
    const { t } = useTranslation();
    console.log("Home Work>>",HomeActivity);

    // useEffect(()=>{
    //             if(HomeActivity && HomeActivity.HomeWork){
    //              const jsonstring = HomeActivity.HomeWork
    //              const parsedData = JSON.stringify(jsonstring)
    //              console.log("parsedData>",parsedData);
    //             }
    // },[HomeActivity])
    // Function to parse the homework text
    const parseHomework = (text) => {
      if (!text) return [];
      const sections = text.split(/\n##/).filter(Boolean);
      return sections.map(section => {
          const [task, ...instructions] = section.trim().split('\n- ');
          return {
              task: task.replace(/^\*\*(.*)\*\*$/, '$1').trim(),
              instructions: instructions.map(instruction => instruction.trim()),
          };
      });
  };
  
  
  useEffect(() => {
    const generateData = async () => { // Changed function name to generateData and declared it as async
        if (HomeActivity && HomeActivity.HomeWork) {
            try {
                const homeworkTasks = await parseHomework(HomeActivity.HomeWork);
                console.log("homeWorkTask>>", homeworkTasks);
                setALLTask(homeworkTasks)
            } catch (error) {
                console.error("Error parsing homework:", error); // Added error handling
            }
        }
    };
    
    generateData();
}, [HomeActivity]);
 
  
  // Example of how to log the tasks
  const showExitPopup = () => {
    setCurrentPopShow("exitPopUp")
  }

  
  const handleSubmitAndExit = async () => {
    // const score = scoreCalculation();
    history.goBack()
  }
  const showSubmitPopUp = () => {
    setCurrentPopShow("scoreForm")
  }

  return (
    <div className='home_work_sec'>
        <div className='home_work_sec_inner'>
    {allTask.map((subTask, index) => {
      return (
        <div key={index}> {/* Use a key prop for each item */}
          <h6>{subTask.task}</h6>
          <p>{subTask.instructions.map((item)=>{
            return <div key={index}>{item}</div> // Use a key prop
          })}</p> {/* Assuming instructions is an array */}
        </div>
      );
    })}
     <Grid className='question_btn_list' item xs={12} container justifyContent="center">
     <Button  className='custom_btn'
                color="primary"
                variant="contained"
                style={{ marginLeft: 5 }}
                onClick={() => {
                    showExitPopup();
                }}
              >
                {t("Back")}
              </Button>
              <Button className='custom_btn'
                color="primary"
                variant="contained"
                style={{ marginLeft: 5 }}
                onClick={() => {
                  // saveAnswers();
                  showSubmitPopUp()
                }}
              >
                {t("Submit")}
              </Button>

     </Grid>
     {
              currentPopShow === "exitPopUp" &&
              <PopUpWarning 
                heading={"Exit"} 
                subHeading={"After that you exit form activity"} 
                leftButton={"Cancel"} 
                rightButton={"Exit"} 
                handleLeftButton={() => {
                  setCurrentPopShow(null)
                }} 
                handleRightButton={async() => {
                  await handleSubmitAndExit()
                }}
              />
            }
            {
              currentPopShow === "scoreForm" &&
              <PopUpNextActivity 
                heading={"Score"} 
                // subHeading={`Your Score : ${score.score} out of ${score.maxScore}`} 
                leftButton={`${t("Save & Exit")}`} 
                rightButton={`${t("Save & Continue")}`} 
                // loading = {scoreLoading}
              />
            }
            </div>
  </div>

  )
}

export default HomeWorkActivities
