import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import { BgColor, Color, Fonts } from "../../theme/index";
import { Header } from "../../components";
import { userDefaultImage } from "../../assets/index";
import openSocket from 'socket.io-client';
import {
    Container,
    Button,
    TextField,
    Box,
    Grid,
    Card,
    Typography,
    Input,
} from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import { SOCKET_URL } from './../../config/weChatConfig';

import {styles as questionStyles} from '../../styles/question';

const socket = openSocket(SOCKET_URL);

const useStyles = makeStyles(() => (Object.assign({}, questionStyles, {
    container: {
        alignSelf: "flex-end",
         
        paddingTop: 70,
        paddingBottom: 20,
    },
    mainHeading: {
        fontSize: "20px",
        fontFamily: Fonts.Medium,
    },
    NotificationProfileName: {
        color: Color.textBlue,
        fontSize: "18px",
        fontFamily: Fonts.Medium,
    },
    smallestBlueTitle: {
        color: Color.textBlue,
        fontSize: "13px",
        fontFamily: Fonts.Regular,
        textTransform: "uppercase",
    },
    notificationTitle: {
        color: "rgba(25,25,25,.4)",
        fontSize: "13px",
        fontFamily: Fonts.Medium,
        textTransform: "uppercase",
    },
    smallText: {
        color: BgColor.myBlack,
        fontSize: "20px",
        fontFamily: Fonts.Regular,
    },
    mainHeading2: {
        fontSize: "25px",
        fontFamily: Fonts.Medium,
    },
    shareCodeText: {
        color: Color.myBlack,
        fontSize: "18px",
        fontFamily: Fonts.Medium,
        textTransform: "uppercase",
        textAlign: "center",
    },
    blueButton: {
        width: "100%",
        height: "40px",
        color: "#fff",
        borderRadius: "5px",
        fontFamily: Fonts.Medium,
        backgroundColor: BgColor.myBlue,
        padding: "0 20px",
        "&:hover": {
            color: Color.textBlue,
            border: "1px solid" + Color.textBlue,
        },
    },
    whiteButton: {
        width: "100%",
        height: "40px",
        color: Color.textBlue,
        fontFamily: Fonts.Medium,
        borderRadius: "5px",
        backgroundColor: "#fff",
        border: "1px solid " + BgColor.myBlue,
        padding: "0 20px",
        margin: "5px 0",
    },
    subTitleLight: {
        color: "rgba(25, 25, 25,.4)",
        fontSize: "11px",
        fontFamily: Fonts.Medium,
        textTransform: "uppercase",
        margin: "0",
    },
    hoursText: {
        color: Color.textMyBlack,
        fontSize: "16px",
        fontFamily: Fonts.Medium,
        margin: "0",
    },
    timeAndDateLight: {
        color: "rgba(25, 25, 25,.4)",
        fontSize: "11px",
        fontFamily: Fonts.Medium,
        textTransform: "uppercase",
        margin: "0",
    },
    profileImg: {
        width: "50px",
        height: "50px",
        borderRadius: "50px",
    },
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: "10px",
        textAlign: "center",
        color: Color.textMyBlack,
    },
    messageImages: {
        width: "50px",
        height: "50px",
        borderRadius: "5px",
        marginRight: "5px",
        marginBottom: "15px",
    },
    sendButton: {
        color: "#fff",
        width: "100%",
        height: "140px",
        borderRadius: "10px",
        marginTop: "10px",
        marginLeft: "10px",
        backgroundColor: BgColor.myBlue,
        "&:hover": {
            color: Color.textBlue,
            border: "1px solid" + Color.textBlue,
        },
    },
    sendingMessageDivLeft: {
        float: "right",
        maxWidth: "100%",
        width: "100%",
        height: "140px",
        overflow: "auto",
        // padding: "10px",
        borderRadius: "10px",
        marginTop: "10px",
        flex: "7",
    },
    mainHeading: {
        fontSize: "20px",
        fontFamily: Fonts.Medium,
    },
    textBoxIcons: {
        color: Color.textBlue,
        fontSize: "20px",
        cursor: "pointer",
    },
    likeThumb: {
        color: Color.textBlue,
        cursor: "pointer",
        transition: "all .3s ease",
        "&:hover": {
            opacity: ".5",
        },
    },
    overlayWrapper: {
        position: "fixed",
        top: "0",
        left: "0",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        zIndex: "2000",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    uploadedFiles: {
        marginLeft: "30px",
        color: "#2a30ff",
    },
})));

const WhiteCard = ({ heading, textContent, contentClassName }) => {
    const styles = useStyles();

    return (
        <>
            <Card
                style={{
                    maxWidth: "100%",
                    width: "100%",
                    padding: "10px",
                    borderRadius: "10px",
                    marginTop: "10px",
                }}
            >
                {/* WhiteCard testing detail */}
                <CardContent style={{ padding: "16px" }}>
                    {console.log("title===> ", heading)}
                    {console.log("textContent===> ", textContent)}
                    <Box>
                        <Box className={styles.notificationTitle}>{heading }</Box>
                        <Box className={styles.smallText + ' ' + (contentClassName || '')}>
                            <div dangerouslySetInnerHTML={{__html: textContent }}></div>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    );
};


const NotificationDetail = (props) => {
    console.log("notification detail props", props)
    const {t , i18n} = useTranslation();
    //const history = useHistory();
    const styles = useStyles();
    let localResponse = useLocation();
    //profileImage, userName, title, title_cn, description
    const data = localResponse.state;
    console.log("notification details data", data);


    return (
        <>
            <Header history={props.history} />
            <Container maxWidth="xl" className={styles.container}>
                <>
                    <Box flexWrap="wrap" p={2} m={0} bgcolor="background.paper">
                        <Typography className={styles.mainHeading}></Typography>
                        <Box
                            display="flex"
                            flexDirection="row"
                            flexWrap="wrap"
                            paddingTop="15px"
                            bgcolor="background.paper"
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12}>

                                    <Box>
                                        <Card
                                            style={{
                                                maxWidth: "100%",
                                                width: "100%",
                                                borderRadius: "10px",
                                                padding: "10px",
                                                marginTop: "10px",
                                            }}
                                        >
                                            <CardContent style={{ padding: "16px" }}>
                                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                                    <Box
                                                        display="flex"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                    >
                                                        <Box pr={"10px"}>
                                                            <a href={(data.profileImage) ? data.profileImage : userDefaultImage} target="_blank" download>
                                                                <img src={(data.profileImage) ? data.profileImage : userDefaultImage} className={styles.profileImg} />
                                                            </a>
                                                        </Box>
                                                        <Box>
                                                            <Box className={styles.NotificationProfileName} py={"2px"}>
                                                                {data.userName === undefined ? "Admin" :
                                                                    data.userName
                                                                }
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </CardContent>
                                        </Card>

                                        {t("privacy_policy") == "Privacy Policy" ? (
                                            <WhiteCard
                                                heading={"title"}
                                                textContent={data.title}
                                            />
                                        ):(
                                            <WhiteCard
                                                heading={"title"}
                                                textContent={data.title_cn ? data.title_cn : data.title}
                                            />
                                        )}

                                        <WhiteCard
                                            heading={"description"}
                                            textContent={data.description}
                                            contentClassName={styles.questionDescription}
                                        />

                                    </Box>

                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </>

            </Container>
        </>
    );
}

export default (NotificationDetail);
