import React, { useEffect, useState } from 'react'
import {
  Button,
  Box,
  makeStyles,
} from "@material-ui/core";
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import axios from "axios";
import Const from "../../helpers/const";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Header from "../../components/header";
import { Fonts, BgColor, Color } from "../../theme";
import { Column, Row, Item } from "@mui-treasury/components/flex";
import { Info, InfoSubtitle, InfoTitle } from "@mui-treasury/components/info";
import { useApexInfoStyles } from "@mui-treasury/styles/info/apex";

const styles = (theme) => ({
  overlayWrapper: {
    position: "fixed",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    zIndex: "2000",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
});


const useStyles = makeStyles(() => ({
  container: {
    alignSelf: "flex-end",
    marginTop: -150,
    paddingTop: 70,
    paddingBottom: 20,
    width: "auto",
  },
  root: {
    maxWidth: 345,
    marginTop: 10,
    marginRight: 10,
  },
  media: {
    height: 140,
  },
  mainHeading: {
    fontSize: "20px",
    fontFamily: Fonts.Medium,
  },
}));


const cardStyles = makeStyles(() => ({
  root: {
    marginRight: 0,
    marginTop: 0,
    height: "100%",
    transition: "0.3s",
    position: "relative",
    // "&:before": {
    //   transition: "0.2s",
    //   position: "absolute",
    //   width: "100%",
    //   height: "100%",
    //   content: '""',
    //   display: "block",
    //   backgroundColor: "#d9daf1",
    //   borderRadius: "1rem",
    //   zIndex: 0,
    //   bottom: 0,
    // },
    "&:hover": {
      // "&:before": {
      //   bottom: -6,
      // },
      // "& $card": {
      //   boxShadow: "-12px 12px 64px 0 #bcc3d6",
      // },
    },
  },
  card: {
    zIndex: 1,
    position: "relative",
    borderRadius: "12px",
    // boxShadow: "0 6px 20px 0 #dbdbe8",
    // boxShadow:
    //   "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    backgroundColor: "#fff",
    transition: "0.3s",
    height: "100%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#3F51B5",
    padding: "20px 20px",

    "&:hover": {
      // boxShadow: "none !important",
      // border: "0 !important",
    },
  },
  logo: {
    width: 48,
    height: 48,
    borderRadius: "0.75rem",
  },
  avatar: {
    fontSize: "0.875rem",
    backgroundColor: "#6d7efc",
  },
  item: {
    display: "flex"
  },
  join: {
    color: "#fff",
    borderRadius: "14px",
    fontSize: "18px",
    padding: "0 25px",
    // lineHeight: "60px",
    marginRight: "10px",
    // backgroundColor: "#a4c4f9",
    backgroundColor: "#3F51B5",
    // background: "linear-gradient(to top, #638ef0, #82e7fe)",
    "& > *": {
      textTransform: "none !important",
    },
    "&:hover": {
      backgroundColor: "#689CF2",
    },
  },
  delete: {
    color: "#fff",
    borderRadius: "14px",
    fontSize: "18px",
    padding: "0 25px",
    lineHeight: "60px",
    // backgroundColor: "#a4c4f9",
    backgroundColor: "#3F51B5",
    // background: "linear-gradient(to top, #638ef0, #82e7fe)",
    marginRight: "0",
    "& > *": {
      textTransform: "none !important",
    },
    "&:hover": {
      backgroundColor: BgColor.myDarkRed,
    },
  },
  danger: {
    color: "#fff",
    fontSize: "14px",
    fontFamily: Fonts.Regular,
    borderRadius: "50px",
    backgroundColor: "#F48FB1",
    "& > *": {
      textTransform: "none !important",
    },
    "&:hover": {
      backgroundColor: "#ff266f",
    },
  },
}));


const CourseSubTopic = () => {
  const { t, i18n } = useTranslation();
  const Styles = cardStyles();
  const history = useHistory();
  const classes  = useStyles();
  const { id, weekId } = useParams();
  const authUser = useSelector((store) => store.auth.user);

  const [loading, setLoading] = useState(false)
  const [allTopics, setTopics] = useState([])
  // const [openAllCourse, setOpenAllCourse] = useState(false)
//   const [selectedContent, setSelectedContent] = useState("")

const getCourses = async () => {
  try {
    const response = await axios.get(Const.BASE_URL + "data/user/" + authUser.id);
    console.log("id>>>>",id);
    console.log("courses>>>", response.data.data?.filter(course => course.id == id));
    setTopics(response.data.data?.filter(course => course.id == id)[0].detailedCourseBreakdown?.weeks[weekId].hours)
  } catch (error) {
    console.log("Error getting courses:", error);
  }
}

useEffect(() => {
  getCourses()
}, [])


  const getCreatedLeason = async () => {
    try {
      setLoading(true)
      const response = await axios.get(`${Const.BASE_URL}get/${id}/subtopics`)
      console.log('response : ', response);
      if(response.status === 200){
        
        toast.success(response.data.message,{autoClose: 2000})
      }
    } catch (error) {
      toast.error("Internal Server Error",{autoClose: 2000})
    } finally {
      setLoading(false)
    }
  }

  const handleOpenCourse = async (value) => {
    const content = value?.lesson_plans[0]?.lesson_plan?.content;
    //setSelectedContent(content);
   // setOpenAllCourse(true)
  }

  const handleDeleteTopic = async (value, topicindex) => {
    console.log('value: ', value, " -> ", topicindex);    
    // try {
    //   const response = await axios.delete(
    //     Const.BASE_URL + `topic/${id}/delete`,
    //   );
    //   if(response.status === 200){
    //     setTopics((prev) => prev.filter( (topic) => topic.topic.lesson_id !== id))
    //     toast.success('Topic Delete Successfully',{autoClose: 2000})
    //   }
      
    // } catch (error) {
    //   toast.error("Internal Server Error",{autoClose: 2000})
    // }
  }


  // useEffect( () => {
  //   getCreatedLeason()
  // }, [])

  return (
    <div  className='topics_gener_main'>
     <Header history={history} />
      <div style={{height: "200px", "background": "rgb(136, 155, 255)", width: "100%"}}></div>
      <Container maxWidth="xl" className={classes.container + " opened-classes-main ourse_creator_outer"} >
      <Box className="ourse_creator_inner no_bg" display="flex" flexWrap="wrap" justifyContent="space-between" flexDirection="row">
        <Button className="custom_btn_style" style={{ margin: '10px' }} type='button' variant="contained" color="primary" onClick={()=> {history.goBack()}}><span className="MuiButton-label">{t("Back") }</span></Button>
      </Box>
      <Box className="top_sec_outer topics_inner" flexWrap="wrap" p={3} m={0} bgcolor="background.paper" borderRadius={10} display="flex">
        {allTopics.length > 0 ?
          allTopics && allTopics?.map((value, topicindex) => (
            <Grid className="card_row_outer" item xs={12} md={6} lg={4} key={topicindex} style={{marginBottom: "15px"}}>
              <div className={Styles.root}>
                <div className={Styles.card}>
                  <Row className='inner_row_sec' p={2} gap={2}>
                    <Info className="card_info" position={"middle"} useStyles={useApexInfoStyles} p={0}>
                      <Box className="card_box"
                        fontSize={"18px"}
                        letterSpacing={"0px"}
                        lineHeight={"20px"}
                        fontWeight={"bold"}
                        minHeight={"50px"}
                        fontFamily={Fonts.Medium}
                        color={Color.textMyBlack}
                        marginBottom={"10px"}
                        textAlign={"left"}
                      >
                        <p style={{textAlign: "left"}}>{t("Topic")}:- {value.topic}</p>
                        <p style={{textAlign: "left"}}>{t("Content")}:- </p>
                        {
                          value?.subtopics?.map( (subtopic, index) => (
                            <div className='inner_desc' style={{textAlign: "left"}}>
                            <div style={{textAlign: "left"}} key={index}>{t("Sub-topic")} :- {t(`${subtopic.subtopic}`)}</div>
                            <p style={{fontSize: "15px", textAlign: "left"}} key={index}>{subtopic.description}</p>
                            </div>
                          ))
                        }
                      </Box>
                    </Info>
                  </Row>
                  <Box className="card_box" pb={1} px={2} color={"grey.600"} fontSize={"0.875rem"} fontFamily={"Ubuntu"}></Box>
                  <Row p={2} gap={2} position={"bottom"} className="card_row sub_topic_btn">
                    <Item className={Styles.item} position={"center"}>
                      <Button className={Styles.join} variant={"contained"} onClick={
                        () => history.push({
                          pathname: `/course-creator/topics/lessonPlanner/${id}/${weekId}/${topicindex}`,
                          state: { topicName: value.topic }
                        })
                      }
                      >
                        {t('Generate new Lessons')}
                      </Button>
                      <Button className={Styles.join} variant={"contained"} onClick={() => history.push(`/course-creator/topics/allLessonPlanner/${id}/${weekId}/${topicindex}`)} >
                        {t('Generated lesson')}
                      </Button>
                    </Item>
                  </Row>
                </div>
              </div>
            </Grid>
          ))
        :
        <span>No Subtopic Topic found !</span>
        }
            
        </Box>
        </Container>
    </div>
  )
}

export default CourseSubTopic