import React, { useEffect, useMemo, useRef, useState } from 'react'
import JoditEditor from 'jodit-react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Grid, Button } from '@material-ui/core';

import PopUpWarning from './PopUpWarning';
import PopUpNextActivity from './PopUpNextActivity';
import { ImCross } from "react-icons/im";
import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';
import config from './writingChatBot/config';
import ActionProvider from './writingChatBot/ActionProvider';
import MessageParser from './writingChatBot/MessageParser';
import WritingChatbot from './writingChatBot/WritingChatbot';

const WritingActivity = ({writingActivity,actions }) => {
  console.log("writingActivity: ", writingActivity);
  const chatbotRef = useRef();

  const { t, i18n } = useTranslation();
  const history = useHistory();
  const editor = useRef(null);
	const [content, setContent] = useState('');
  console.log("editor>>",editor,content);
  
  const [topicOptions, setTopicOptions] = useState(writingActivity?.activities ?? [])
  const [selectWritingActivity, setSelectWritingActivity] = useState(null);

  const [isSubmit, setIsSubmit] = useState(false);
  const [currentPopShow, setCurrentPopShow] = useState(null);
  const [writingScore, setWritingScore] = useState({score: 0, maxScore: 100});

  const [isLoading, setIsLoading] = useState(false);
  const [scoreLoading, setScoreLoading] = useState(false);
  const [suggest,SetSuggest] = useState(null)
  const [contentUser,setContentUser] = useState(null)
  console.log("contentUser in writingact",contentUser);
  
  let typingTimer = useRef(null); // Ref to store the typing timer
  console.log("writingactivityscore",selectWritingActivity);
  

  //popup functions
  const handelShowExistPopup = () => {
    if(isSubmit){
      history.goBack();
      return ;
    }
    setCurrentPopShow("exitPopUp")
  }

  const handelSaveAnswer = () => {
    setCurrentPopShow("submitPopUp")
  };

  const getScoreWritingActivity = async () => {
    const response = await axios.post("https://irevu.openai.azure.com/openai/deployments/irevu4o0/chat/completions?api-version=2024-02-15-preview",
      {
        messages: [
          {
            "role": "system",
            "content": `I will provide a writing activity topic (${JSON.stringify(selectWritingActivity)}) for evaluation based on IELTS standards. You will receive the user's written content, and your task is to evaluate it and give a score out of 100%.`
          },
          {
            "role": "system",
            "content": `Here is the user's written content: ${JSON.stringify(content)}.`
          },
          {
            "role": "system",
            "content": `Evaluate the writing based on IELTS standards considering grammar, vocabulary, coherence, cohesion, and task achievement.`
          },
          {
            "role": "system",
            "content": `Please return your evaluation as a JSON object in the following format without any additional text or headings: \n{\n  \"score\": 85 // The total score out of 100%\n}`
          }
        ],
        max_tokens: 1000,
        temperature: 0.1,
        frequency_penalty: 0,
        presence_penalty: -1.5,
        stop: null,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "api-key": "7327443ae85e44419192e4fb0544d702",
        },
      }
    );

    console.log("response>>>>>>>>>>>>>>>>>>>>>>>>",response.data.choices[0].message);
    return JSON.parse(response.data.choices[0].message.content)
  }

  const saveWritingActivity = async () => {
    // todo: implement api's
  }

  const handleSubmitAndExit = () => {
    const saveActivity = saveWritingActivity();
    history.goBack()
  }

  const handleSubmitAndGetScore = async () => {
     setScoreLoading(true);
    setCurrentPopShow("scoreForm");
    const writingScore =await getScoreWritingActivity();
    setWritingScore(writingScore);
    console.log("setwringscoreinside>>",writingScore);
    
    const saveActivity = await saveWritingActivity();
    setIsSubmit(true);
    setScoreLoading(false)
  }


  useEffect(() => {
    if(writingActivity && writingActivity.Writing){
      const StringyfyJSon =writingActivity.Writing
      const ParseData = JSON.parse(StringyfyJSon)
      console.log("parseData>>",ParseData.activities);
      const ParsedActivities = ParseData.activities
      setTopicOptions(ParsedActivities)
    }
    
  }, [writingActivity])
  
  console.log("writingActivity-----: ", topicOptions);

 const handleChange = (cont) => {
  console.log("Content changed: ", cont);
  // const { actions } = chatbotRef.current.props;
  // Clear the previous timer if any
  clearTimeout(typingTimer.current);

  // Step 1: Clean up the content
  // Replace <br> tags with \n for consistent handling of line breaks
  const cleanedContent = cont
    .replace(/<\/?p>/g, "") // Remove <p> tags
    .replace(/<br\s*\/?>/gi, "\n") // Replace <br> with \n
    .replace(/&nbsp;/g, " ") // Replace non-breaking spaces with regular spaces
    .trim();
   
  console.log("Cleaned Content: ", cleanedContent);

  // Step 2: Split content into paragraphs based on blank lines (2+ line breaks)
  const paragraphs = cleanedContent
    .split(/\n{1,}/) // Split by two or more consecutive line breaks
    .map((para) => para.trim()) // Trim spaces around each paragraph
    .filter(Boolean); // Remove empty strings

   console.log("Paragraphs after splitting: ", paragraphs);
  
  // Step 3: Extract the paragraph before the last blank line
    const contentBeforeLastBlankLine =
    paragraphs.length > 1 ? paragraphs[paragraphs.length - 2] : "";
   
   console.log("Extracted content before last blank line: ", contentBeforeLastBlankLine);

  // Step 4: Handle the extracted content
  if (contentBeforeLastBlankLine) {
    // Set a new timer that calls `getRealTimeSuggestion` after 3 seconds
    setContentUser(contentBeforeLastBlankLine)
    typingTimer.current = setTimeout(async () => {
      // const FinalSuggestion = await getRealTimeSuggestion(contentBeforeLastBlankLine);
      // SetSuggest(FinalSuggestion);
      if (actions && actions.handleMessage) {
        console.log("inside acion activity");
        
        // Call handleMessage to send both user and bot messages
        actions.handleMessage("helo men");
      } 
      // console.log("Final Suggestions: ", FinalSuggestion);
    }, 3000); // 3 seconds delay after the user stops typing
  } else {
    console.log("No valid content before the last blank line, function not called.");
  }
};

  
  const getRealTimeSuggestion = async (cont) => {
    const userContent = cont.trim(); // Trim whitespace
    const originalWordCount = userContent.split(' ').length;
  
    // Avoid sending an empty or invalid content request
    if (!userContent) {
      console.log("Content is empty or invalid.");
      return "Please provide more content to generate meaningful suggestions.";
    }
  
    // Make the API call for suggestions
    try {
      const response = await axios.post(
        "https://irevu.openai.azure.com/openai/deployments/irevu4o0/chat/completions?api-version=2024-02-15-preview",
        {
          messages: [
            {
              role: "system",
              content: `You will evaluate and enhance user-written content related to a specific topic (${JSON.stringify(
                selectWritingActivity
              )}). Your task is to improve the grammar, coherence, and vocabulary of the content while ensuring it logically progresses the paragraph.`,
            },
            {
              role: "user",
              content: `Here is the user's current content: ${JSON.stringify(userContent)}`,
            },
            {
              role: "system",
              content: `Provide a unique, topic-relevant suggestion for improving this content. Avoid generic or repetitive phrases. Ensure the suggestion does not exceed the user's word count by more than 20 words and aligns with the topic: "${selectWritingActivity}".`,
            },
          ],
          max_tokens: 1000,
          temperature: 0.7,
          frequency_penalty: 0.5,
          presence_penalty: 0.6,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "api-key": "7327443ae85e44419192e4fb0544d702",
          },
        }
      );
  
      const suggestion = response.data.choices[0].message.content.trim();
  
      // Ensure suggestion is concise and unique
      const suggestionWordCount = suggestion.split(' ').length;
  
      if (
        suggestionWordCount <= originalWordCount + 20 &&
        suggestion !== userContent // Ensure the suggestion is not identical
      ) {
        console.log("Suggestion: ", suggestion);
        return suggestion;
      } else {
        console.log("Suggestion exceeds limit or is repetitive. Trimming response.");
        const trimmedResponse = suggestion.split(' ').slice(0, originalWordCount + 20).join(' ') + '.';
        return trimmedResponse;
      }
    } catch (error) {
      console.error("Error generating suggestion: ", error.message);
      return "Could not generate a suggestion at this time. Please try again later.";
    }
  };
  
  
  // const handleoverlay =(()=>{
  //   console.log("hellooverlay");
    
  //   setContent(suggest)
  //   SetSuggest(null)
  // })
  
  const handleExit=(()=>{
    console.log("Exit");
    SetSuggest(null)
  })
  
	return (
    <div className='page_outer writing_outer'>
    <div className='inner_box'>
    {
        isLoading && <div>
          Loading ...
        </div>
      }
     <div className='top_heading'>
     <h2><span>Writing Activity</span></h2>
    {selectWritingActivity == null ?  <p>Pick your favorite Activity topic from the list below and follow the instructions carefully!</p> : ""} 
     </div>
    {selectWritingActivity &&<p className='special_or'>{ selectWritingActivity?.activityName}</p>}
     <div className='page_inner'>
 
      {
        selectWritingActivity === null ? 
          (
          <div className='writing_topic'>
            {
              topicOptions?.map((activity, index) => (
                  <div className='activity_list'  >
                  <div className='desg_wrt_act-btn'><div className='activity_name'>Activity Topic:- {activity?.activityName}</div>
                  <button onClick={() => setSelectWritingActivity(activity)}>Start</button></div>
                  <div className='activity_list_inner'>
                    {
                      <>
                        <p>Instructions</p>
                      {activity?.instruction.map( (instru, index) => (
                        <div className='index_num'>{index+1}. {instru}</div>
                      ))}
                      </>
                    }
                  </div>
                </div>
              ))
            }
          </div>
          )
          : (
            <div className='writing_editor uniqe_editor'>
              {suggest && (
              <div className="suggestion-overlay">
                {/* <div on onClick={handleExit}><ImCross /></div> */}
               {/* <p onClick={handleoverlay}>{suggest}</p>  */}
              </div>
            )}
            <div className='jodit_Chat'>
              <JoditEditor
             
                title={suggest}
                ref={editor}
                value={content}
                config={{placeholder: "Writing Essays Here .....", readonly: isSubmit, toolbarSticky: true}}
                tabIndex={1}
                onBlur={newContent => {console.log("NC",newContent) ;setContent(newContent)}}
                onChange={newContent  => handleChange(newContent)}
              /> 
              </div>
&nbsp;
{/* <div>
      <Chatbot
        config={config}
        messageParser={MessageParser}
        actionProvider={ActionProvider}
      />
    </div> */}
{/* {content &&  */} 
    <WritingChatbot
    suggest={suggest}
    contentUser={contentUser}
    selectWritingActivity={selectWritingActivity}
    />
{/* } */}
            </div>
            
          )
      }
    </div>
    </div>
    {
        selectWritingActivity &&
        <>
          <Grid className='question_btn_list' item xs={12} container justifyContent="center">
                <Button className='custom_btn'
                  color="primary"
                  variant="contained"
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    handelShowExistPopup();
                  }}
                >
                  {t("Back")}
                </Button>

                <Button className='custom_btn'
                  color="primary"
                  variant="contained"
                  style={{ marginLeft: 5 }}
                  disabled={isSubmit}
                  onClick={() => {
                    handelSaveAnswer();
                  }}
                >
                  {t("Submit")}
                </Button>
{/* 
                <Button className='custom_btn'
                  color="primary"
                  variant="contained"
                  style={{ marginLeft: 5 }}
                  disabled={isSubmit}
                  onClick={() => {
                    handelSaveAnswer();
                }}
                >
                  {t("Save & Continue")}
                </Button> */}

                {/* <Button className='custom_btn'
                  color="primary"
                  variant="contained"
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    handelShowExistPopup()
                  }}
                >
                  {t("Save & Exit")}
                </Button> */}

              </Grid>
              <div className='after_save_outer'>
            {
              currentPopShow === "submitPopUp" &&
              <PopUpWarning
               heading={"Submit"}
               subHeading={"After that you can't change your answer"}
               leftButton={"Cancel"} rightButton={"Submit"}
               handleLeftButton={() => {setCurrentPopShow(null)}}
               handleRightButton={async () => {await handleSubmitAndGetScore()}}/>
            }
            {
              currentPopShow === "exitPopUp" &&
              <PopUpWarning
               heading={"Exit"}
               subHeading={"After that you exit form activity"}
               leftButton={"Cancel"}
               rightButton={"Exit"}
               handleLeftButton={() => {setCurrentPopShow(null)}}
               handleRightButton={() => {handleSubmitAndExit()}}/>
            }
             </div>
             {
              currentPopShow === "scoreForm" &&
              <PopUpNextActivity 
                heading={"Score"} 
                subHeading={`Your Score : ${writingScore.score} out of 100`} 
                leftButton={`${t("Save & Exit")}`} 
                rightButton={`${t("Save & Continue")}`} 
                loading = {scoreLoading}
              />
            }
        </>
    }
    </div>  
	);
}

export default WritingActivity