import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import PopUpWarning from './PopUpWarning';
import PopUpNextActivity from './PopUpNextActivity';

const GistQuestionsActivity = ({gistQuestion, lessonId, formattedActivityType}) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState(Array(gistQuestion?.length ?? 0).fill(''));
  const [correctAnswerIndex, setCorrectAnswerIndex] = useState(Array(gistQuestion?.length ?? 0).fill(''));
  // const [correctAnswers, setCorrectAnswers] = useState(gistQuestion?.map(q => q.answer) ?? []);
  
  const [isSubmit, setIsSubmit] = useState(false);
  const [scoreCard, setScoreCard] = useState({ score: 0, totalScore: questions?.length, correctAnswersIndex: [] });
  const [currentPopShow, setCurrentPopShow] = useState(null);
  const[correctQuestionAnswer,setCorrectQuestionAnswer] = useState([])
  const[content,setContent] = useState([])
  const [scoreLoading, setScoreLoading] = useState(false);
  const [lesson, setLesson] = useState(null)
  const [loading, setLoading] = useState(false);



  const GenrateQAStudyCompersion = async (gistQuestion) => {
    const response = await axios.post("https://irevu.openai.azure.com/openai/deployments/irevu4o0/chat/completions?api-version=2024-02-15-preview",
      {
        messages: [
          {
            role: "system",
            content: `You are an AI assistant trained to generate  comprehension questions and answers based on a provided passage. 
                    The comprehension passage will be passed dynamically, and all questions must be derived strictly from the content of the passage to ensure relevance. 
                    Stay focused on the key ideas, themes, and details in the passage when creating the questions. 
                    Each question should include the following:\n\n- A clear and concise question \n\n- A correct answer clearly marked\n\nAdditionally, generate a structured lesson plan with content that references and explains each question and answer. 
                    The lesson plan should guide learners to understand the main ideas of the passage, using the questions as reference points for deeper comprehension.
                    Ensure the output is in this JSON format only:
                    {
                      "LessonContent": "Artificial Intelligence (AI) is the branch of computer science focused on creating systems capable of mimicking human intelligence to perform tasks like learning, 
                                        reasoning, and decision-making. AI is categorized into narrow AI, which specializes in specific tasks, and general AI, which mimics human-level intelligence across 
                                        diverse activities. Machine learning, a subset of AI, uses data to train models for predictions without explicit programming. AI has transformative applications in 
                                        healthcare, finance, and education, enhancing diagnostics, fraud detection, and personalized learning. However, ethical considerations, such as bias and job 
                                        displacement, alongside risks like privacy breaches, demand responsible development and regulation.",
                      "comprehensionQuestion": [
                          {
                            "question": "Define Artificial Intelligence (AI)",
                            "answer": "Artificial Intelligence (AI) is a branch of computer science that aims to create machines capable of performing tasks that usually require human intelligence. 
                                        These tasks include learning, reasoning, problem-solving, and understanding language.
                          },
                          {
                            "question": "Describe one real-world application of AI in healthcare",
                            "answer": "One real-world application of AI in healthcare is the early detection of diabetic retinopathy using AI-based image analysis to identify patterns and anomalies in retinal images."
                          }
                        ],
                    }
                    If the passage does not contain enough information to generate questions or if any part of the content is unclear, indicate that more information is needed in the "LessonContent".`
          },
          {
            role: "user",
            content: `Generate compersion, questions and answers related to this details: ${JSON.stringify(gistQuestion)}`
          }
        ],
        max_tokens: 4000,
        temperature: 0.1,
        frequency_penalty: 0,
        presence_penalty: -1.5,
        stop: null,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "api-key": "7327443ae85e44419192e4fb0544d702",
        },
      }
    );
    return response.data.choices[0].message.content
  }


  useEffect(() => {
    if (gistQuestion && gistQuestion['Gist Questions']) {
      const gistQuestionsString = gistQuestion['Gist Questions'].replace(/^```json|```$/g, '');
      const Lessonstring = gistQuestion["LessonContent"]
    
      try {
        const parsedData = JSON.parse(gistQuestionsString);


        setContent(parsedData.LessonContent)        
        // const parseContent = JSON.parse(Lessonstring)
        // If parsing is successful, map to create an array of question-answer pairs
        const questions = parsedData.GistQuestions.map(q => (
          ( q.question)
        ));
        const Answer = parsedData.GistQuestions.map(q => ({
          answer: q.answer
        }));
        const CorrectquestionsAnswer = parsedData.GistQuestions.map(q => ({
          question :q.question,
          answer : q.answer
        }
          
        ));
        setCorrectQuestionAnswer(CorrectquestionsAnswer)
        setQuestions(questions);
      } catch (error) {
        console.error("Error parsing gist questions:", error);
      }
    } else {
      console.warn("No Gist Questions found or gistQuestion is undefined.");
    }
      
    if(gistQuestion && gistQuestion["Comprehension questions"]){
      // const questionAnswerArray = [];

      // const questionAnswerRegex = /\*\*\d+\.\s+(.+?)\*\*\s+([\s\S]+?)(?=\*\*|$)/g;

      // let match;
      // while ((match = questionAnswerRegex.exec(gistQuestion["Comprehension questions"])) !== null) {
      //   const question = match[1].trim();
      //   const answer = match[2].replace(/-/g, '').trim();

      //   questionAnswerArray.push({ question, answer });
      // }
      // setCorrectQuestionAnswer(questionAnswerArray)
      // const Allquestion=questionAnswerArray.map((q)=>{
      //   return (
      //     q.question
      //   )
      // })
      // console.log("questions: ", Allquestion)
      // setQuestions(Allquestion)

      // const Allanswer=questionAnswerArray.map((q)=>{
      //   return (
      //     q.answer
      //   )
      // })
    }
    
  }, [gistQuestion]); 


  useEffect(() => {
    const fetchQAData = async () => {
      setLoading(() => true);
      const response = await GenrateQAStudyCompersion(gistQuestion);
      const ParseQAO=JSON.parse(response)

      setLesson(ParseQAO?.LessonContent)
      setQuestions(ParseQAO?.comprehensionQuestion?.map((item) => item?.question))
      setCorrectQuestionAnswer(ParseQAO?.comprehensionQuestion)
      setLoading(() => false);
    };

    if(formattedActivityType === 'Comprehension questions' && gistQuestion && gistQuestion["Comprehension questions"]) {
      fetchQAData();
    }
  }, [gistQuestion , formattedActivityType]);


 
 
  
  // Handle answer changes
  const handleChange = (index, value) => {
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  };

  // Calculate score
  const scoreCalculation = async () => {
    // let score = 0;
    // const correctWrongAnswer = answers.map(answer => false); 
    
    

    // for (let index = 0; index < correctAnswers.length; index++) {
    //   if (answers[index]?.trim()?.toLowerCase() === correctAnswers[index]?.trim()?.toLowerCase()) {
    //     score++;
    //     correctWrongAnswer[index] = true;
    //   }
    // }
    // setCorrectAnswerIndex(correctWrongAnswer);
    // return { score: score, totalScore: correctAnswers.length };

    const response = await axios.post("https://irevu.openai.azure.com/openai/deployments/irevu4o0/chat/completions?api-version=2024-02-15-preview",
      {
        messages: [
          {
            role: "system",
            content: `I will provide you with questions and their correct answers for reference: ${JSON.stringify(correctQuestionAnswer)}. 
            Use these answers only to check the user's answers, which are in the same order as the correct answers. 
            When comparing, allow for slight variations in wording, but focus on preserving the overall meaning.`
          },
          {
             role: "system",
             content: `
              Here are the user's answers: ${JSON.stringify(answers)} (the answers are in array form and in the same order as the questions).
            `
          },
          {
             role: "system",
             content: `
              Compare each user's answer with the reference answers, allowing for slight variations in phrasing, wording, or structure, but ensuring the overall meaning is preserved. 
              Return the total score (number of correct answers), and indicate which answers are correct by providing their 0-based indexes in the array.
              Please return the response strictly in JSON format without any additional text or headings.
      
              Example output format:
              {
                "score": 4,  // The total score based on correct answers
                "maximumScore": 6,  // The total number of questions
                "correctAnswersIndex": [1, 3, 5, 6]  // An array of the correct answer indexes (0-based)
              }
            `
          }
        ],
        max_tokens: 1000,
        temperature: 0.1,
        frequency_penalty: 0,
        presence_penalty: -1.5,
        stop: null,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "api-key": "7327443ae85e44419192e4fb0544d702",
        },
      }
    );

    return JSON.parse(response.data.choices[0].message.content)
  };

  // Handle showing the exit popup
  const handelShowExistPopup = () => {
    if (isSubmit) {
      history.goBack();
      return;
    }
    setCurrentPopShow("exitPopUp");
  };

  // Handle saving the answer
  const handelSaveAnswer = () => {
    setCurrentPopShow("submitPopUp");
  };

  // Handle the submission and get score
  const handleSubmitAndGetScore = async () => {

    // setCurrentPopShow(null);
    setScoreLoading(true);
    setCurrentPopShow("scoreForm");
    const score = await scoreCalculation();
    setScoreCard(score);
    setScoreLoading(false);
    setIsSubmit(true);
  };

  // Handle submit and exit
  const handleSubmitAndExit = () => {
    const score = scoreCalculation();
    history.goBack();
  };

  // Initialize on gistQuestion change
  useEffect(() => {
    // setQuestions(gistQuestion?.map(q => q.question) ?? []);
    // setAnswers(Array(gistQuestion?.length ?? 0).fill(''));
    // setCorrectAnswers(gistQuestion?.map(q => q.answer) ?? []);
  }, [gistQuestion]);

  return (
    <div className='page_outer gist_page'>
    <div className='inner_box'>
     <div className='top_heading'>
      {gistQuestion["Gist Questions"] ?(
      <h2><span>Gist Question</span></h2>
      ):(
        <h2><span>Comprehension Question</span></h2>
      )
      }
      {/*  */}
       </div>
       {content && content?.map((i)=>{
        return (<div>{i}</div>)
       })}
       {
        loading &&
        <div className="spinner"></div>
       }
       {lesson && <p>{lesson}</p> }
      <div className='page_inner'>
      
      {questions.map((question, index) => (
        <div className='input_box' container key={index} alignItems="center" spacing={2} mb={2}>
          <div className='inner_fild' item xs={12} sm={8}>
            <div className='inner_div'>
            <div className='label_tag'>{`${index + 1}. ${question}`}</div>
            <input
              value={answers[index]}
              onChange={(e) => handleChange(index, e.target.value)}
              disabled={isSubmit}
              placeholder="Write your answer here....."
            />
            </div>
            {
              isSubmit && <span>
                {
                  scoreCard?.correctAnswersIndex?.includes(index) ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />
                }
              </span>
            }
          </div>
        </div>
      ))}

      <Grid className='question_btn_list' item xs={12} container justifyContent="center">
        <Button  className='custom_btn'
          color="primary"
          variant="contained"
          style={{ marginLeft: 5 }}
          onClick={() => {
            handelShowExistPopup();
          }}
        >
          {t("Back")}
        </Button>

        <Button  className='custom_btn'
          color="primary"
          variant="contained"
          style={{ marginLeft: 5 }}
          disabled={isSubmit}
          onClick={() => {
            handelSaveAnswer();
          }}
        >
          {t("Submit")}
        </Button>

        {/* <Button  className='custom_btn'
          color="primary"
          variant="contained"
          style={{ marginLeft: 5 }}
          disabled={isSubmit}
          onClick={() => {
            handelSaveAnswer();
          }}
        >
          {t("Save & Continue")}
        </Button>

        <Button  className='custom_btn'
          color="primary"
          variant="contained"
          style={{ marginLeft: 5 }}
          onClick={() => {
            handelShowExistPopup();
          }}
        >
          {t("Save & Exit")}
        </Button> 
          */}

      </Grid>
      <div className='after_save_outer'>
      {/* Popups */}
      {
        currentPopShow === "submitPopUp" &&
        <PopUpWarning 
          heading={"Submit"} 
          subHeading={"After that you can't change your answers."} 
          leftButton={"Cancel"} 
          rightButton={"Submit"} 
          handleLeftButton={() => setCurrentPopShow(null)} 
          handleRightButton={async () => await handleSubmitAndGetScore ()}
        />
      }
      {
        currentPopShow === "exitPopUp" &&
        <PopUpWarning 
          heading={"Exit"} 
          subHeading={"Are you sure you want to exit the activity?"} 
          leftButton={"Cancel"} 
          rightButton={"Exit"} 
          handleLeftButton={() => setCurrentPopShow(null)} 
          handleRightButton={handleSubmitAndExit}
        />
      }

      {/* Score Display */}
      </div>
     
           {
              currentPopShow === "scoreForm" &&
              <PopUpNextActivity 
                heading={"Score"} 
                subHeading={`Your Score : ${scoreCard.score} out of ${questions?.length}`} 
                leftButton={`${t("Save & Exit")}`} 
                rightButton={`${t("Save & Continue")}`} 
                loading = {scoreLoading}
              />
            }

    </div>
    </div>
    </div>
  );
};

export default GistQuestionsActivity