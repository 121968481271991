import React, { useEffect, useState } from "react";
import FormCardAI from "./FormCardAI";
import Container from "@material-ui/core/Container";
import { Box, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Header from "../../components/header";

import Typography from "@mui/material/Typography";
import { logo } from "../../assets";
import axios from "axios";
import Const from "../../helpers/const";
import axiosOptions from "../../helpers/axiosOptions";
import { useSelector } from "react-redux";
const styles = (theme) => ({
  overlayWrapper: {
    position: "fixed",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    zIndex: "2000",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
});

function LessonPlanAI(props) {
  const { classes } = props;
  const history = useHistory();
  const [state, setState] = useState({
    openLessonPlan: false,
    addingNewLessonPlan: false,
    addingNewAIPlan: false,
    addingNewResource: false,
    payCard: false,
    receiptCard: false,
  });
  const [loading, setLoading] = useState(true);
  const authUser = useSelector((store) => store.auth.user);

  useEffect(() => {
    checkActivePlan();
  }, [authUser]);

  const closeAddingNewAIPlan = () => {
    setState({
      addingNewAIPlan: !state.addingNewAIPlan,
    });
  };

  const open = (toBeOpened) => {
    setState({
      [toBeOpened]: !state[toBeOpened],
    });
  };

  const checkActivePlan = async () => {
    try {
      const response = await axios.post(
        Const.BASE_URL + "check_genie_status",
        { user_id: authUser?.id },
        axiosOptions(localStorage.userToken)
      );

      if (response) {
        const planData = response?.data?.data;
        const currentDate = new Date();
        const planExpiryDate = new Date(planData?.vaild_upto);

        if (
          response?.data?.status === "false" ||
          planData?.price !== "45" ||
          planData?.words_lenght === 0 ||
          currentDate > planExpiryDate
        ) {
          history.push("/subscription");
        } else {
          setLoading(false);
        }
      }

      setLoading(false);
    } catch (error) {
      let err = error.response ? error.response.data : error;
      console.log("Error in checking active plan:", err);
      history.push("/subscription");
    }
  };

  return (
    <>
      <Container
        maxWidth="xl"
        style={{
          alignSelf: "flex-end",
          paddingLeft:125,
          paddingRight:125,
          paddingTop: 70,
          paddingBottom: 20,
        }}
      >
        <Header history={history} />
        {!loading && (
 <div className='page_outer'>
          <Box flexWrap="wrap" p={2} m={0} bgcolor="background.paper">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <FormCardAI closeAddingNewAIPlan={closeAddingNewAIPlan} />
            </Box>
          </Box>
          </div>
        )}
      </Container>
    </>
  );
}

export default LessonPlanAI;
