import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';

//icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import PopUpWarning from './PopUpWarning';
import PopUpNextActivity from './PopUpNextActivity';

const DefinitionMatchingActivity = ({definitionMatching, lessonId}) => {

  console.log("definitionMatching:", definitionMatching );

  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [availableAnswers, setAvailableAnswers] = useState([]);
  const [parsedDefinationMetching,setParsedDefinationMetching] = useState([])
  const [scoreLoading, setScoreLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [scoreCard, setScoreCard] = useState({score: 0, totalScore: parsedDefinationMetching.length})
  const [currentPopShow, setCurrentPopShow] = useState(null);

   console.log("parseddefinationmatching",parsedDefinationMetching.length)
   console.log("availableanswer>>",availableAnswers);
   
   console.log("scorecrad",scoreCard);
   console.log("selectedanswer>>", Object.keys(selectedAnswers).length);
   
   
  useEffect(() => {
    if (definitionMatching && definitionMatching["Definition Match"]) {
      let stringyjsondata = definitionMatching["Definition Match"];
      // Remove "```json" prefix and postfix if present
      // if(definitionMatching){
      //   let Titles = definitionMatching.replace(/^```json\s*/i, '').replace(/\s*```$/i, '')

      // }
      stringyjsondata = stringyjsondata.replace(/^```json\s*/i, '').replace(/\s*```$/i, '');
      try {
        const jsonData = JSON.parse(stringyjsondata);
        console.log("jsonData:", jsonData);
        setParsedDefinationMetching(jsonData.DefinationMatchQuestions)
        const AnswerJson=jsonData?.DefinationMatchQuestions
        console.log("answerJson>>",AnswerJson);
        
        const shuffledAnswers = [...AnswerJson.map(q => q.answer)].sort(() => Math.random() - 0.5);
        console.log("shuffledAnswer>>>",shuffledAnswers);

        setAvailableAnswers(shuffledAnswers)
        
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
  }, [definitionMatching]);

  // const handleSelectAnswer = (questionIndex, answer) => {
  //   const newSelectedAnswers = { ...selectedAnswers, [questionIndex]: answer };
  //   setSelectedAnswers(newSelectedAnswers);

  //   // Remove the selected answer from the available list
  //   setAvailableAnswers(availableAnswers.filter(a => a !== answer));
  // };


  const handleSelectAnswer = (questionIndex, answer) => {
    const newSelectedAnswers = { ...selectedAnswers, [questionIndex]: answer };
    setSelectedAnswers(newSelectedAnswers);
  
    // Remove only one occurrence of the selected answer from the available list
    const updatedAvailableAnswers = [...availableAnswers];
    const indexToRemove = updatedAvailableAnswers.indexOf(answer);
    if (indexToRemove !== -1) {
      updatedAvailableAnswers.splice(indexToRemove, 1);
    }
    setAvailableAnswers(updatedAvailableAnswers);
  };
  
console.log("available answer>>>",availableAnswers);

  // const handleRemoveAnswer = (questionIndex) => {
  //   const answerToRemove = selectedAnswers[questionIndex];
  //   const newSelectedAnswers = { ...selectedAnswers };
  //   delete newSelectedAnswers[questionIndex];
  //   setSelectedAnswers(newSelectedAnswers);

  //   // Add the removed answer back to available answers
    
  //   setAvailableAnswers([...availableAnswers, answerToRemove]);
  // };

  const handleRemoveAnswer = (questionIndex) => {
    const answerToRemove = selectedAnswers[questionIndex];
    const newSelectedAnswers = { ...selectedAnswers };
    delete newSelectedAnswers[questionIndex];
    setSelectedAnswers(newSelectedAnswers);
  
    // Add the removed answer back to the available list at the end
    setAvailableAnswers((prev) => [...prev, answerToRemove]);
  };
  

  const scoreCalculation = () => {
    let score = 0;
    parsedDefinationMetching.forEach((question, index) => {
      if (selectedAnswers[index] === question.answer) {
        score++;
      }
    });
    return {score: score, totalScore: parsedDefinationMetching.length}
  };


  //popup functions
  const handelShowExistPopup = () => {
    if(isSubmit){
      history.goBack();
      return ;
    }
    setCurrentPopShow("exitPopUp")
  }

  const handelSaveAnswer = () => {
    if (Object.keys(selectedAnswers).length > 0 ){
    setCurrentPopShow("submitPopUp")
    }
  };

  const handleSubmitAndExit = () => {
    const score = scoreCalculation();
    history.goBack()
  }

  const handleSubmitAndGetScore =async () => {
    setScoreLoading(true);
    setCurrentPopShow("scoreForm");
    const score =await scoreCalculation();
    setScoreCard(score);
    // setIsSubmit(true);
    setScoreLoading(false);
  }




  useEffect(() => {
    //randomly shuffled answers
    // const shuffledAnswers = [...definitionMatching.map(q => q.answer)].sort(() => Math.random() - 0.5);
    // setAvailableAnswers(shuffledAnswers);
  }, [definitionMatching]);
  
  return (<div className="defination_mathch_main">
    <div className='page_outer'>
    <div className='inner_box'>
     <div></div>
    <p className="keyboard_cust"> Keyords : {availableAnswers && availableAnswers?.map((answers)=>(
      <span >{answers}, &nbsp;</span>
     ))}
     </p>
          

      {parsedDefinationMetching?.map((question, index) => (
        
        <div className='main_listings input_box' key={index}>
        <div className='in_listing'>
        <div class="label_tag">{`${index+1}. `}{question.question}</div>

          {selectedAnswers[index] 
          ? ( <span className='fil_divider'>
              <p>Selected Answer: {selectedAnswers[index]}</p>
              <button disabled={isSubmit} onClick={() => handleRemoveAnswer(index)}>Remove</button>
            </span>
          ) 
          : ( <select
              onChange={(e) => handleSelectAnswer(index, e.target.value)}
              defaultValue=""
              disabled={isSubmit}
            >
              <option value="" disabled>Select an answer</option>
              {availableAnswers.map((answer, idx) => (
                <option key={idx} value={answer}>
                  {answer}
                </option>
              ))}
            </select>
          )}
          </div>
          {
            currentPopShow === "scoreForm" && <span className='check_close_fil'>
              {
                selectedAnswers[index] === parsedDefinationMetching[index].answer ? <CheckCircleIcon /> : <CancelIcon />
              }
            </span>
          }
        </div>
      ))}</div>
      <Grid className='question_btn_list' item xs={12} container justifyContent="center">
        <Button className='custom_btn'
          color="primary"
          variant="contained"
          style={{ marginLeft: 5 }}
          onClick={() => {
            handelShowExistPopup();
          }}
        >
          {t("back")}
        </Button>

        <Button className='custom_btn'
          color="primary"
          variant="contained"
          style={{ marginLeft: 5 }}
          disabled={isSubmit}
          onClick={() => {
            handelSaveAnswer();
          }}
        >
          {t("submit")}
        </Button>
{/* 
        <Button className='custom_btn'
          color="primary"
          variant="contained"
          style={{ marginLeft: 5 }}
          disabled={isSubmit}
          onClick={() => {
            handelSaveAnswer();
        }}
        >
          {t("save_and_continue")}
        </Button>
                  
        <Button className='custom_btn'
          color="primary"
          variant="contained"
          style={{ marginLeft: 5 }}
          onClick={() => {
            handelShowExistPopup()
          }}
        >
          {t("save_and_exit")}
        </Button> */}

      </Grid>
      
      <div className='after_save_outer'>
      {
        currentPopShow === "submitPopUp" &&
        <PopUpWarning heading={"Submit"}
          subHeading={"After that you can't change your answer"}
          leftButton={"Cancel"} rightButton={"Submit"} 
          handleLeftButton={() => {setCurrentPopShow(null)}} 
          handleRightButton={async() => {await handleSubmitAndGetScore()}}/>
      }
      {
        currentPopShow === "exitPopUp" &&
        <PopUpWarning 
        heading={"Exit"} 
        subHeading={"After that you exit form activity"} 
        leftButton={"Cancel"} rightButton={"Exit"} 
        handleLeftButton={() => {setCurrentPopShow(null)}} 
        handleRightButton={async () => {await handleSubmitAndExit()}}/>
      }
      </div>
      {
              currentPopShow === "scoreForm" &&
              <PopUpNextActivity 
                heading={"Score"} 
                subHeading={`Your Score : ${scoreCard.score} out of ${scoreCard.totalScore}`} 
                leftButton={`${t("Save & Exit")}`} 
                rightButton={`${t("Save & Continue")}`} 
                loading = {scoreLoading}
              />
            }
    </div>
    </div>
    
  )
}

export default DefinitionMatchingActivity