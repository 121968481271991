import React, { useEffect, useState } from 'react'
import { Grid,Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PopUpWarning from './PopUpWarning';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PopUpNextActivity from './PopUpNextActivity';
const ReadingActivities = ({ReadingActivity}) => {
  const [currentPopShow, setCurrentPopShow] = useState(null);
  const history = useHistory()
  const { t } = useTranslation();
 const parseText = (text) => {
    // Split the text by new lines
    const lines = text.split('\n').map(line => {
      // Check if the line has heading marked by **
      if (line.startsWith('**') && line.endsWith('**')) {
        const heading = line.slice(2, -2); // Remove the **
        return <h2 key={heading}>{heading}</h2>; // Return as a heading
      }
      return <p key={line}>{line}</p>; // Return as a paragraph
    });
    return lines;
  };

    // Example of how to log the tasks
    const showExitPopup = () => {
      setCurrentPopShow("exitPopUp")
    }
  
    
    const handleSubmitAndExit = async () => {
      // const score = scoreCalculation();
      history.goBack()
    }
    const showSubmitPopUp = () => {
      setCurrentPopShow("scoreForm")
    }
 
  return (
    <div className='reading-main-ac'>
      {ReadingActivity.map((item, index) => (
        <div key={index}>
          {parseText(item.Reading)}
        </div>
      ))}
           <Grid className='question_btn_list' item xs={12} container justifyContent="center">
     <Button  className='custom_btn'
                color="primary"
                variant="contained"
                style={{ marginLeft: 5 }}
                onClick={() => {
                    showExitPopup();
                }}
              >
                {t("Back")}
              </Button>
              <Button className='custom_btn'
                color="primary"
                variant="contained"
                style={{ marginLeft: 5 }}
                onClick={() => {
                  // saveAnswers();
                  showSubmitPopUp()
                }}
              >
                {t("Submit")}
              </Button>

     </Grid>
     {
              currentPopShow === "exitPopUp" &&
              <PopUpWarning 
                heading={"Exit"} 
                subHeading={"After that you exit form activity"} 
                leftButton={"Cancel"} 
                rightButton={"Exit"} 
                handleLeftButton={() => {
                  setCurrentPopShow(null)
                }} 
                handleRightButton={async() => {
                  await handleSubmitAndExit()
                }}
              />
            } 
            {
              currentPopShow === "scoreForm" &&
              <PopUpNextActivity 
                // heading={"Score"} 
                // subHeading={`Your Score : ${score.score} out of ${score.maxScore}`} 
                leftButton={`${t("Save & Exit")}`} 
                rightButton={`${t("Save & Continue")}`} 
                // loading = {scoreLoading}
              />
            }
    </div>
  );
};
export default ReadingActivities
